<template>
  <b-container class="quotecontainer">
    <b-row class="">
      <b-col cols="10" offset="1" class="quotecol">
        <h1 class="quote">{{ quote.quote }}</h1>
        <h3 class="name">- {{ quote.speaker }}</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span class="mt-3 float-right">
          <a :href="'/quote/' + quote.id" class="" size="sm ml-3"><b-icon icon="link"></b-icon> Permalink</a>
          |
          <a href="/" class="" size="sm ml-3"><b-icon icon="arrow-clockwise"></b-icon> Fresh Quote</a>
          |
          <a :href="apiurl"><b-icon icon="cloud"></b-icon> API</a>
        </span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Quote',
  computed: {
    apiurl() {
      return process.env.VUE_APP_API_URL
    }
  },
  data() {
    return {
      quote: ''
    }
  },
  created() {
    this.$router.onReady(() => {
      this.loadquote(this.$route.params.id)
    })
  },
  methods: {
    loadquote() {
      var qs = ''
      if (this.$route.params.id != null) {
        qs = '/quote/' + this.$route.params.id
      }
      axios.get(process.env.VUE_APP_API_URL + qs)
        .then(response => {
          this.quote = response.data
        })
    }
  }
}
</script>

<style scoped>
.quotecontainer {
  font-family: 'IBM Plex Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}
.quotecol {
  background-color: #b8bcc4;
  padding: 30px;
  border-radius: 25px;
}
.quote {
  font-size: 2.0rem;
  font-weight: 400;
  color: #2c2f33;
  margin-bottom: 40px;
}
.name {
  font-size: 1.8rem;
  font-weight: 200;
  font-style: italic;
  color: #23272a;
}
</style>
